<template>
<div id="privacy">
  <div class="container">
    <h2 v-if="!$route.query.lang" class="privacy-title">{{$t('user.privacy_title')}}</h2>
    <div v-html="$t('user.privacy')"></div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  mounted(){
    if(this.$route.query.lang ){
      this.$i18n.locale = this.$route.query.lang 
      document.title = this.$t('user.privacy_title')
    }
  },
}
</script>
<style lang="scss">
#privacy{
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1rem 1.2rem;
  .privacy-title{
    text-align: center;
    margin-bottom: 1rem;
  }
  .subtitle{
    font-weight: 600;
    text-indent: 0;
  }
  p{
    text-indent: 1em;
  }
}
</style>
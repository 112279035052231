<template>
  <div id="download">
    <div class="cont">
      <img class="logo" src="../assets/download_logo.png" alt="" srcset="" />
      <div class="slogan-cn">让信号一直在您身边{{useragent}}</div>
      <div class="slogan-en">The signal is on your side</div>
      <img class="bg-img" src="../assets/download_bg.png" alt="" srcset="" />
      <img
        class="btn-img"
        @click="download"
        src="../assets/download_btn.png"
        alt=""
        srcset=""
      />
    </div>
    <van-overlay :show="show" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-icon
            color="#666666"
            class="close"
            @click="show = false"
            style="cursor: pointer"
            size="2.5rem"
            name="cross"
          />
          <div class="modal-cont">
            <div class="title">{{ $t("footer.download") }}</div>
            <div class="download-qrcode">
              <vue-qr
                :text="ios.url"
                :size="270"
                :margin="0"
                :logoSrc="ios.icon"
                :logoScale="0.2"
              ></vue-qr>
              <vue-qr
                :text="Android.url"
                :size="270"
                :margin="0"
                :logoSrc="Android.icon"
                :logoScale="0.2"
              ></vue-qr>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Toast } from "vant";
import VueQr from "vue-qr";
export default {
  components: { VueQr },
  data() {
    return {
      userAgent:'',
      show: false,
      useragent: "",
      Android: {
        url: "",
        icon: require("../assets/2.png"),
      },
      ios: {
        url: "https://apps.apple.com/cn/app/id1367310342",
        icon: require("../assets/1.png"),
      },
    };
  },
  watch: {
    show() {
      if (this.show) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
  },
  created() {
    if (this.$route.query.lang) {
      this.$i18n.locale = this.$route.query.lang;
      document.title = this.$t("footer.download");
    }
    this.userAgent = navigator.userAgent
    console.log(navigator.userAgent);
    if (
      navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
        "micromessenger" &&
      navigator.userAgent.indexOf("Android") > -1
    ) {
      this.useragent = "Wechant";
    } else if (
      navigator.userAgent.indexOf("iOS") > -1 ||
      navigator.userAgent.indexOf("Mac") > -1
    ) {
      this.useragent = "iOS";
    } else if (navigator.userAgent.indexOf("Android") > -1) {
      this.useragent = "Android";
    } else if (navigator.userAgent.indexOf("Windows") > -1) {
      this.useragent = "Windows";
    }
    console.log(this.useragent);
  },
  methods: {
    download() {
      this.$http.get(this.APIURL.HOME.DOWNLOAD).then((res) => {
        if (res.code == 1) {
          this.Android.url = res.data.version.url;
          switch (this.useragent) {
            case "Wechant":
              window.location.href = 'https://sj.qq.com/myapp/detail.htm?apkName=com.tgcyber.hotelmobile&info=7C45CBA1EAF0FF902FAED47F9B067E86'
              break;
            case "Android":
              window.location.href = this.Android.url;
              break;
            case "iOS":
              window.location.href = this.ios.url;
              break;
            case "Windows":
              this.show = true;
              this.Android.url = "http://sim.triproaming.cn/download";
              break;
          }
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#download {
  user-select: none;
  color: #222222;
  .block {
    width: 65rem;
    .download-qrcode {
      padding: 4rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .cont {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .slogan-cn {
    font-size: 3rem;
  }
  .slogan-en {
    font-size: 2rem;
  }
  .logo {
    margin-bottom: 2rem;
  }
  .bg-img {
    display: none;
    margin-top: 4rem;
  }
  .btn-img {
    width: 24.8rem;
    cursor: pointer;
    height: auto;
    margin-top: 4rem;
  }
}
@media (max-width: 768px) {
  #download {
    color: #222222;
    .slogan-cn {
      font-size: 1.6rem;
    }
    .slogan-en {
      font-size: 1.3rem;
    }
    .logo {
      width: 15.1rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .bg-img {
      display: block;
      width: 26.6rem;
      height: auto;
      margin-top: 3rem;
    }
  }
}
</style>
<template>
  <div class="search">
    <div class="container">
      <div class="flex-start">
        <div class="search-title flex-start" @click="$router.replace({ name: 'TopUp'})">
            <van-icon name="arrow-left" size="2.5rem" color="#2564F6" />
            <span>{{$t('common.search_2')}}</span>
        </div>
      </div>
      <a-row v-if="Results.length" class="product-list" type="flex" justify="start" :gutter="[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]">
        <a-col class="wrapper-item" :xs="12" :md="8" :lg="8" :xl="6" v-for="(item,index) in Results" :key="index">
          <div class="inner-item" @click="$router.push({path: '/details', query: {id:item.id}})">
            <van-image show-loading show-error fit="contain" lazy-load :src="item.image">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <div class="product-grid-info">
              <div class="product-name">
                {{item.store_name}}
              </div>
              <div class="flex-between product-price">
                <span>
                    {{item.price}}
                </span>
                <van-icon name="arrow" color="#999"/>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <van-empty v-else :image="$route.query.keyword ? 'error':'search'" :description="$route.query.keyword ? $t('common.search_3'):$t('common.search_4')" />
    </div>
  </div>
</template>
<script>
import { Products } from '@/api/api.js'
export default {
  data() {
    return {
      Results:[]
    }
  },
  watch:{
    '$route.query.keyword':{
      immediate:true,
      handler(value){
        this.init(value)
      }
    }
  },
  // created(){
  //   this.init(this.$route.query.keyword)
  // },
  methods:{
    async init(value){
      if(!value) return this.Results = []
      const { data } = await Products({keyword:value})
      this.Results = data.list
    }
  }
}
</script>
<style lang="scss" scoped>
.search{
  .search-title{
    color: $color;
    font-size: 2rem;
    font-weight: 600;
    margin-top:2rem;
    cursor: pointer;
  }
}
</style>
<template>
  <div class="details">
    <a-spin size="large" :spinning="loading">
      <div class="container">
        <div class="produce-details">
          <a-row :gutter="[{ xs: 0, sm: 20, md: 40 }, { xs: 20, sm: 40 }]">
            <a-col class="flex" :xs="24" :sm="8" :md="8">
              <van-image round radius="5px" fit="contain" show-loading show-error :src="product.image">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </a-col>
            <a-col :xs="24" :sm="16" :md="16">
              <div class="produce-name">{{ product.store_name }}</div>
              <div class="produce-price">
                <span>{{ $t('common.price_1') }}</span>
                <span class="present">HK$ {{ price.present || '--' }}</span>
                <span class="cost">HK$ {{ price.cost }}</span>
              </div>
              <van-divider />
              <a-row :gutter="[10, { xs: 35, sm: 35, md: 35, lg: 20 }]" type="flex">
                <a-col :xs="7" :sm="6" :lg="4" :xl="3" class="label">
                  {{ $t('common.title_5') }}
                  <van-popover v-model="showPopover" placement="top-start">
                    <van-grid square clickable :border="false">
                      <van-image width="200" height="250" lazy-load fit="cover" :src="require('../assets/iccid_tw.png')">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </van-grid>
                    <template #reference>
                      <van-icon name="question-o" @mouseover="showPopover = true" @mouseleave="showPopover = false" />
                    </template>
                  </van-popover>
                </a-col>
                <a-col :xs="17" :sm="18" :lg="12" :xl="10">
                  <van-field class="sim-number" 
                    v-model="order.sim_number" 
                    type="number" 
                    maxlength="20" 
                    clearable
                    :placeholder="$t('common.tup-op_tips')"
                    />
                </a-col>
              </a-row>
              <a-row :gutter="[10, { xs: 35, sm: 35, md: 35, lg: 20 }]" type="flex">
                <a-col :xs="7" :sm="6" :lg="4" :xl="3" class="label">{{ $t('common.title_2') }}</a-col>
                <a-col :xs="17" :sm="18" :md="18" :lg="20" :xl="21">
                  <div class="suk-pc">
                    <div class="wrapper" v-for="(item, index) in options" :key="index">
                      <div class="inner" @click="sukSelect(item)" :class="{ 'suk-active': item.unique === order.unique }">
                      {{item.suk }} HK${{ item.price }}</div>
                    </div>
                  </div>
                  <div :class="[isExpansion ? 'expansion':'collapse']" class="suk-xs">
                    <div class="wrapper" v-for="(item, index) in options" :key="index">
                      <div class="inner" @click="sukSelect(item)" :class="{ 'suk-active': item.unique === order.unique }">
                      {{item.suk }} HK${{ item.price }}</div>
                    </div>
                  </div>
                  <div class="expansion-text flex" @click="isExpansion = !isExpansion">{{ isExpansion ? '收起' : `查看其他套餐` }}<van-icon :name="isExpansion ? 'arrow-up':'arrow-down'" /></div>
                </a-col>
                <a-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
                  <van-button type="primary" class="check-out" :disabled="!order.sim_number" @click="CreateOrderHandle" color="#B50000">{{ $t('common.btn_2') }}</van-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div class="explain">
          <div class="title">{{ $t('common.title_3') }}</div>
          <div class="text" v-html="product.description"></div>
        </div>
      </div>
    </a-spin>
    <div class="bottom-sticky">
      <div style="padding: 10px 25px;" class="flex-between">
        <div class="sticky-price">
          <div class="sticky-present"> HK$ {{ price.present }}</div>
          <div class="sticky-cost"> HK$ {{ price.cost }}</div>
        </div>
        <van-button type="primary" round class="check-out" :disabled="!order.sim_number" @click="CreateOrderHandle" color="#B50000">{{ $t('common.btn_2') }}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ProductDetail, CheckCardNumber, AddCart, ConfirmOrder, CreateOrder } from '@/api/api.js'
export default {
  data() {
    return {
      showPopover:false,
      isExpansion:false,
      loading: false,
      product: {},
      options: {},
      price: {
        present: '',
        cost: ''
      },
      order: {
        sim_number: '',
        unique: ''
      },
      errMessage:''
    }
  },
  created() {
    this.init()
  },
  mounted() {
    // if (this.$route.query.locale) {
    //   switch (this.$route.query.locale) {
    //     case 'zh-cn':
    //       localStorage.setItem('sim_lang', this.$route.query.locale)
    //       this.$i18n.locale = this.$route.query.locale
    //       break;
    //     case 'zh-tw':
    //       localStorage.setItem('sim_lang', 'zh-cn')
    //       this.$i18n.locale = 'zh-cn'
    //       break;
    //     case 'en':
    //       localStorage.setItem('sim_lang', this.$route.query.locale)
    //       this.$i18n.locale = this.$route.query.locale
    //       break;
    //   }
    // }
  },
  methods: {
    async init() {
      this.loading = true
      const { data } = await ProductDetail(this.$route.query.id)
      this.product = data.productInfo
      this.product.description = data.productInfo.description.replace(/height=*?/s,'height="auto"').replace(/width=*?/s,'width="100%"').replace(/width:*?/s,'width:100%;')
      this.options = data.productValue
      const firstKey = Object.keys(data.productValue)[0]
      this.sukSelect(data.productValue[firstKey])
      this.loading = false
    },
    sukSelect(item) {
      const { unique, price, ot_price } = item
      this.order.unique = unique
      this.price = { present: price, cost: ot_price }
    },
    async CreateOrderHandle(){
      const { unique, sim_number } = this.order
      const verify = await CheckCardNumber(sim_number)
      if(verify.status !== 200) return
      const { data } = await AddCart({ product_id:this.$route.query.id,unique:unique, cart_num:1, new:1})
      const info = await ConfirmOrder({ cart_ids:data.cart_id, new:1 })
      const custom_form = JSON.parse(this.product.custom_form)
      custom_form[0].value = sim_number
      const result = await CreateOrder({ payType:"weixin", custom_form },info.data.orderKey)
      if(result.status !== 200) return
      this.$message.success(result.msg)
      this.$router.push({ name: 'Cashier', query: { orderId:result.data.result.orderId}});
    },
    // async CheckCardNumberHandle(){
    //   const result = await CheckCardNumber(this.order.sim_number)
    //   if(result.status !== 200) this.errMessage = result.msg
    // }
  },
}
</script>
<style lang="scss" scoped>

.details {
  .produce-details {
    margin: 40px 0;

    .label {
      color: #333333;
      font-size: 16px;
      user-select: none;
    }

    .check-out {
      min-width: 15rem;
      max-width: 20rem;
      font-size: 1.7rem;
      border-radius: 5px;
    }

    .produce-name {
      font-size: 20px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .produce-price {
      display: block;
      padding: 20px;
      color: #FFF;
      font-size: 16px;
      background-color: #3A9EFB;

      .present {
        font-size: 32px;
        margin: 0 8px 0 20px;
      }

      .cost {
        font-size: 16px;
        text-decoration: line-through;
      }
    }
    .sim-number {
      background-color: #F5F7FA;
      padding: 1rem 2rem;
      border-radius: 4px;
    }
    .expansion-text{
      display: none;
    }
    .suk-pc {
      display: flex;
      justify-content: start;
      flex-flow: wrap;
    }
    .suk-xs{
      display: none;
    }
    .wrapper {
      padding: 5px;

      .inner {
        min-width: 78px;
        padding: 0 20px;
        min-height: 40px;
        font-size: 16px;
        user-select: none;
        font-weight: 600;
        border-radius: 5px;
        border: 1px solid #C6C6C6;
        color: #222222;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .suk-active {
        background-color: rgba(58, 158, 251, 0.1);
        color: #3A9EFB;
        border-color: #3A9EFB;
      }
    }
  }
  .explain {
    padding: 40px 0;

    .title {
      font-weight: bold;
      color: $color;
      font-size: 20px;
    }

    .subtitle {
      color: #333333;
      font-size: 18px;
      margin-top: 20px;
    }

    .text {
      font-size: 16px;
      color: #666666;
      margin-top: 10px;
      p::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  .bottom-sticky {
    display: none;
  }
}
@media (max-width: 1024px) {
  .details {
    .produce-details {
      .suk-pc {
        display: none;
      }
      .suk-xs{
        display: block;
      }
      .collapse{
        max-height: 138px;
        overflow: hidden;
      }
      .expansion{
        max-height: none;
        overflow: auto;
      }
      .expansion-text{
        display: flex;
        margin-top: 10px;
        color:#666666;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
      }
      .wrapper {
        width: 100%;
        padding: 5px;

        .inner {
          min-width: 68px;
          min-height: 36px;
          font-size: 14px;
          padding: 0 12px;
        }
      }
    }
  }
  
}
@media (max-width: 768px) {
  .details {
    .produce-details {

      .produce-name {
        font-size: 18px;
      }

      .produce-price {
        padding: 10px;
        color: #FFF;
        font-size: 16px;
        background-color: #3A9EFB;

        .present {
          font-size: 32px;
          margin: 0 8px 0 20px;
        }

        .cost {
          font-size: 16px;
          text-decoration: line-through;
        }
      }
    }

    .explain {
      padding: 0;

      .title {
        font-weight: bold;
        color: $color;
        font-size: 20px;
      }

      .subtitle {
        color: #333333;
        font-size: 18px;
        margin-top: 20px;
      }

      .text {
        word-wrap: break-word;
        word-break: break-all;
        font-size: 16px;
        color: #666666;
        margin-top: 10px;

        p::before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $color;
        }
      }
    }
    
  }
}
@media (max-width: 575px) {
  .details{
    .produce-details {
      margin: 20px 0;
      .produce-price {
        display: none;
      }
    }
    .bottom-sticky {
      display: block;
      box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      /* iphonex 等安全区设置，底部安全区适配 */
      padding-bottom: constant(safe-area-inset-bottom);
      /* 兼容 iOS < 11.2 */
      padding-bottom: env(safe-area-inset-bottom);
      /* 兼容 iOS >= 11.2 */
      .sticky-price{
        .sticky-present{
          color: #B50000;
          font-size: 22px;
          font-weight: 600;
        }
        .sticky-cost{
          color: #999999;
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="cashier">
    <div class="container">
      <div class="cashier-title">{{$t('common.pay_1')}}</div>
      <div class="subtitle">{{$t('common.pay_2')}}</div>
        <a-row :gutter="[{ xs: 20, sm: 20, md: 40 }, { xs: 20, sm: 40 }]">
          <a-col class="flex" :xs="8" :sm="8" :md="8" :lg="4">
            <van-image round radius="5px" fit="contain" show-loading show-error :src="cartInfo.image">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </a-col>
          <a-col :xs="16" :sm="16" :md="16" :lg="20">
            <div class="produce">
              <div class="name">{{ cartInfo.store_name }}</div>
              <div class="spec">{{ cartInfo.attrInfo.suk}}</div>
              <div>{{$t('common.pay_13')}}<span class="price">HK$ {{ cartInfo.attrInfo.price }}</span></div>
            </div>
          </a-col>
        </a-row>
      <div class="subtitle">{{$t('common.pay_4')}}</div>
      <ul class="flex-start">
        <li class="payment flex-start" @click="payment = item.payment" :class="{'payment-active': payment == item.payment}" v-for="(item,index) in paymentList" :key="index">
          <a-space>
            <input type="radio" :checked="payment == item.payment" />
            <van-image round radius="5px" fit="contain" show-loading show-error :src="item.img">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            {{ item.name }}
          </a-space>
        </li>
      </ul>
      <van-divider />
      <div class="cashier-footer">
          <div class="amount-payable">{{$t('common.pay_5')}}<span class="price">HK$ {{ cartInfo.attrInfo.price }}</span></div>
          <van-button round type="info" @click="CheckOutHandle" color="#B50000" class="check-out">{{$t('common.pay_6')}}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { OrderDetail, OrderPay } from '@/api/api.js'
export default {
  data() {
    return {
      cartInfo:{
        attrInfo:{
          suk:''
        }
      },
      paymentList:[
        // {name:'VISA / MasterCard', payment:'stripe', img:'http://esim.qn.triproaming.cn/uploads/20230323/59dbe71b743a9f34e3610c99da1b1e46.png'},
        {name:'Paypal', payment: 'paypal', img:'https://img.triproaming.cn/yyb_payment_icon_paypal%402x.png'},
      ],
      payment:'paypal'
    };
  },
  created() {
    this.init()
  },
  methods: {
    async init(){
      const { data } = await OrderDetail(this.$route.query.orderId)
      this.cartInfo = data.cartInfo[0].productInfo
      console.log(this.cartInfo)
    },
    async CheckOutHandle(){
      const { data } = await OrderPay({uni:this.$route.query.orderId, payType:this.payment})
      // window.open(data.result.pay_url)
      window.location.href = data.result.pay_url;
    }
  },
 
};
</script>
<style lang="scss" scoped>
.cashier{
  .cashier-title {
    font-size: 2rem;
    color: $color;
    margin-top: 4rem;
    font-weight: 600;
  }
  .subtitle {
    color: #222222;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 1.2rem 2.2rem;
    margin: 2rem 0;
    border-left: 2px solid $color;
    background-color: rgba(37, 100, 246, 0.1);
  }
  .produce{
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .name{
      color: #222222;
      font-size: 2rem;
    }
    .spec{
      color: $color;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 4rem;
    }
    .price{
      color: #222222;
      font-size: 2rem;
      font-weight: 600;
    }
  }
  .payment{
    color: #666;
    font-size: 1.4rem;
    margin: 1rem;
    padding: 1.5rem 2rem;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    border:1px solid #f2f3f6;
  }
  .payment-active{
    border-color: $color;
  }
  .amount-payable{
    font-size: 1.6rem;
    .price{
      font-size: 2.4rem;
      color:#A82115;
      font-weight: 600;
    }
  }
  .cashier-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .check-out{
      margin-left: 2rem;
      min-width: 17rem;
    }
  }
}
@media (max-width: 768px) {
  .cashier{
    .cashier-title {
      font-size: 1.6rem;
      margin-top: 2rem;
    }
    .subtitle {
      font-size: 1.4rem;
      font-weight: 600;
      padding: 1.2rem 2.2rem;
      margin: 2rem 0;
    }
    .produce{
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .name{
        font-size: 1.6rem;
      }
      .spec{
        font-size: 1.6rem;
        margin-bottom: 4rem;
      }
      .price{
        font-size: 2rem;
      }
    }
    .payment{
      font-size: 1.4rem;
      margin: 1rem;
      padding: 1rem;
    }
    .amount-payable{
      font-size: 1.4rem;
      .price{
        font-size: 2rem;
        color:#A82115;
        font-weight: 600;
      }
    }
    .cashier-footer{
      display: flex;
      justify-content: space-between;
      .check-out{
        min-width: 14rem;
      }
    }
  }
}
</style>
<template>
  <div class="enquiry">
    <div class="container">
      <div class="title" :class="[isEnquiry ? 'flex-start' : 'flex']">{{$t('route.enquiry')}}</div>
      <van-divider class="divider" v-show="isEnquiry"/>
      <a-row class="search" type="flex" :justify="isEnquiry ? 'start' : 'center'">
        <a-col :xs="24" :sm="24" :md="24" :lg="12">
          <a-input-search type="number" v-model="value" allowClear placeholder="請輸入卡號" @search="EnquiryHandle" :maxLength="21" size="large">
            <van-icon slot="enterButton" name="search"/>
          </a-input-search>
        </a-col>
      </a-row>
      <a-spin size="large" :spinning="loading">
        <div class="table-page-table-wrapper" v-show="isEnquiry">
          <a-table 
            :columns="columns" 
            row-key="oid" 
            :data-source="records"
            >
            <template slot="usage" slot-scope="text">
              <span>{{ Math.ceil(text/1024/1024) }}</span>
            </template>
            <template slot="active" slot-scope="text">
              <span>{{ text | timeFilter }}</span>
            </template>
            <template slot="expire" slot-scope="text">
              <span>{{ text | timeFilter }}</span>
            </template>
            <template slot="end" slot-scope="text">
              <span>{{ text | timeFilter }}</span>
            </template>
            <template slot="create" slot-scope="text">
              <span>{{ text | timeFilter }}</span>
            </template>
            <template slot="footer" >
              时区：香港 (GMT+8)
            </template>
          </a-table>
        </div>
        <div class="enquiry-card col-12">
          <van-card v-for="(item,index) in records" :key="index">
          <template #title>
            <div class="card-title">{{item.plan_name}}</div>
          </template>
          <template #desc>
            <div class="card-desc">ICCID：{{item.iccid}}</div>
            <div class="card-desc">用量（MB）：{{Math.ceil(item.usage/1024/1024)}}</div>
            <div class="card-desc">时区：香港 (GMT+8)</div>
            <div class="card-desc">激活時間：{{item.active_at | timeFilter}}</div>
            <div class="card-desc">過期時間：{{item.expire_at | timeFilter}}</div>
            <div class="card-desc">終止時間：{{item.end_at | timeFilter}}</div>
            <div class="card-desc">訂單時間：{{item.create_at | timeFilter}}</div>
          </template>
        </van-card>
        </div>
      </a-spin>
    </div>
  </div>
</template>
<script>
import * as dayjs from 'dayjs'
import { Enquiry } from '@/api/api.js'
export default {
  data() {
    return {
      isEnquiry:false,
      value:'',
      loading:false,
      records:[]
    }
  },
  computed:{
    columns(){
      return [
        { title: 'ICCID', dataIndex: 'iccid' },
        { title: '流量套餐', dataIndex: 'plan_name' },
        { title: '狀態', dataIndex: 'status', },
        { title: '用量（MB）', dataIndex: 'usage', scopedSlots: { customRender: 'usage' } },
        { title: '激活時間', dataIndex: 'active_at', scopedSlots: { customRender: 'active' } },
        { title: '過期時間', dataIndex: 'expire_at', scopedSlots: { customRender: 'expire' } },
        { title: '終止時間', dataIndex: 'end_at', scopedSlots: { customRender: 'end' } },
        { title: '訂單時間', dataIndex: 'create_at', scopedSlots: { customRender: 'create' } },
      ]
    }
  },
  filters: {
    timeFilter (time) {
      return time ? dayjs(time * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
    }
  },
  methods:{
    async EnquiryHandle(){
      if(!this.value) return
      this.loading = this.isEnquiry = true
      const { data } = await Enquiry(this.value)
      this.loading = false
      this.records = data
    }
  }
}
</script>
<style lang="scss" scoped>
.enquiry{
  padding: 40px 0;
  .title{
    font-size: 20px;
    color: #222222;
    font-weight: 600;
    user-select: none;
    text-align: center;
  }
  .divider{
    display: block;
  }
  .enquiry-card{
    display: none;
  }
  .search{
    margin: 40px 0;
    
    /deep/ .ant-input-search{
      height: 60px;
      .ant-input-wrapper{
        height: 100%;
        .ant-input-clear-icon{
          font-size: 20px;
        }
        .ant-input{
          height: 100%;
          border-radius: 30px 0 0 30px;
          padding-left: 30px;
          user-select: none;
          &:focus{
            border-color:$color;
            box-shadow: 0 0 0 2px rgba(235, 144, 47, .1);
          }
          &:hover{
            border-color:$color;
            box-shadow: 0 0 0 2px rgba(235, 144, 47, .1);
          }
        }
        .ant-input-group-addon{
          background-color: inherit;
          .ant-btn{
            padding: 0 28px 0 20px;
            height: 100%;
            border-radius:0 30px 30px 0;
            background-color: $color;
            border: 1px solid $color;
            .van-icon{
              font-size: 36px;
            }
          }
        }
      }
    }
  }
  
}
@media (max-width: 768px){
  .enquiry{
    padding: 1.5rem 0;
    .divider{
      display: none;
    }
    .title{
      font-size: 16px;
      justify-content: center;
    }
    .enquiry-card{
      display: block;
      border-radius: 16px;
      .card-title{
        word-break:break-all;
        font-size: 16px;
        font-weight: bold;
        color:#333;
      }
      .card-desc{
        font-size: 14px;
        line-height: 36px;
        color:#333;
      }
    }
    .search{
      margin: 20px 0;
      /deep/ .ant-input-search{
        height: 45px;
        .ant-input-wrapper{
          height: 100%;
          .ant-input-clear-icon{
            font-size: 16px;
          }
          .ant-input-group-addon{
            .ant-btn{
              height: 100%;
              padding: 0 20px 0 15px;
              .van-icon{
                font-size: 25px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
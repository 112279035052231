<template>
  <div class="mine">
    <div class="container">
      <div class="side row">
        <div class="col-lg-3">
          <ul class="side-nav ">
            <li v-for="(item,index) in routerLink" :key="index" :class="{active:$route.name == item.url}">
             <router-link :to="{name:item.url}">{{item.name}}</router-link>
            </li>
            <li @click="logout">
              {{$t("route.logout")}}
            </li>
          </ul>
        </div>
        <router-view class="router-view col-lg-9 col-xs-12"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { Logout } from '@/api/api.js'
export default {
  data() {
    return {
      routerLink: [
        { name: this.$t('route.user') ,url:'User' },
        // { name: this.$t('route.sim_order') ,url:'SimOrder'},
        { name: '全部订单' ,url:'Orders'},
        // { name: this.$t('route.address') ,url:'Addess'},
        // { name: this.$t('route.guide') ,url:'Guide'},
      ],
    }
  },
  methods:{
    async logoutHandler(){
      const result = await Logout()
      localStorage.removeItem('smart_token')
      this.$store.commit('Login', false)
      this.$router.replace({ name: 'TopUp'});
    },
    logout(){
     this.$dialog.confirm({
      title: this.$t('login.logout_title'),
      message: this.$t('login.logout_text'),
      confirmButtonText:this.$t('login.logout_ok'),
      cancelButtonText:this.$t('common.cancel'),
      }).then(() => {
        this.logoutHandler()
      }).catch(() => {
        // on cancel
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.mine{
  padding: 4rem 0;
  .side{
    --bs-gutter-x: 3rem;
    user-select: none;
    .side-nav{
      // height: 50rem;
      border-radius: 4px;
      box-shadow: 0px 2px 12px 0px rgba(48, 49, 51, 0.1);
      padding: 5rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      a{
        color: #222222;
        text-decoration:none;
      }
      .active{
        a{
          font-weight: 600;
        }
        &::after{
          position: absolute;
          bottom: -1rem;
          left: 50%;
          transform: translate(-50%,0);
          content: '';
          width: 8rem;
          height: .3rem;
          background-color: $color;
        }
      }
      li{
        font-size: 1.8rem;
        margin-bottom: 4rem;
        cursor: pointer;
        position: relative;
        
      }
    }
  }
  .router-view{
    border-radius: 4px;
    padding: 2rem;
    box-shadow: 0px 2px 12px 0px rgba(48, 49, 51, 0.1);
  }
}
@media(max-width:992px){
.mine{
  padding: 0;
  margin-top: 1rem;
  .side{
    --bs-gutter-x: 0;
      .side-nav{
        display: none!important;
      }
      .router-view{
        width: 100%;
        border-radius: 0;
        padding: 1rem;
        box-shadow: none;
      }
    }
  }
}
</style>
